
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as dashboardhKii8fXWAxMeta } from "/usr/src/app/pages/dashboard.vue?macro=true";
import { default as _91identifier_937XfjtpS143Meta } from "/usr/src/app/pages/database/[identifier].vue?macro=true";
import { default as _403nSVv3KgT0OMeta } from "/usr/src/app/pages/error/403.vue?macro=true";
import { default as _404WivUUbKe5wMeta } from "/usr/src/app/pages/error/404.vue?macro=true";
import { default as _500Wi55kHlytRMeta } from "/usr/src/app/pages/error/500.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91identifier_93QuSrBUP6SFMeta } from "/usr/src/app/pages/knowledge-base/articles/[identifier].vue?macro=true";
import { default as indexPK4J6TyKtnMeta } from "/usr/src/app/pages/knowledge-base/articles/index.vue?macro=true";
import { default as index9UaQHjx8Q7Meta } from "/usr/src/app/pages/knowledge-base/index.vue?macro=true";
import { default as indexLTysiFnUI5Meta } from "/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue?macro=true";
import { default as indexZZhFjk9ZfGMeta } from "/usr/src/app/pages/knowledge-base/tags/index.vue?macro=true";
import { default as indexl38GLAvKVHMeta } from "/usr/src/app/pages/national-reports/index.vue?macro=true";
import { default as indexsV0YGwxtHhMeta } from "/usr/src/app/pages/national-reports/nr7/index.vue?macro=true";
import { default as indexqAaag6k3KSMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue?macro=true";
import { default as indicator_45datar4FCObtcGyMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue?macro=true";
import { default as overviewf9kPW4zRjgMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue?macro=true";
import { default as section_45annexJuwaxMeKOoMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue?macro=true";
import { default as section_45IOUxLtY92lYMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue?macro=true";
import { default as section_45IIvPfvYsvtFnMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue?macro=true";
import { default as section_45IIIKizDNnkcCkMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue?macro=true";
import { default as section_45IVtc4zFIuIXIMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue?macro=true";
import { default as section_45VCVNGerfoo0Meta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue?macro=true";
import { default as section_45VIgrD5vjTm7tMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue?macro=true";
import { default as indexAMuO34C4sOMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/index.vue?macro=true";
import { default as index6umqEWvPtlMeta } from "/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue?macro=true";
import { default as analyzer1ndP9iNI4QMeta } from "/usr/src/app/pages/national-targets/analyzer.vue?macro=true";
import { default as indexb9IE1lLRN3Meta } from "/usr/src/app/pages/national-targets/index.vue?macro=true";
import { default as indexJ6mjSzSz5lMeta } from "/usr/src/app/pages/national-targets/my-country/index.vue?macro=true";
import { default as editMqCXwCUPWOMeta } from "/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue?macro=true";
import { default as index8uiIbni5p0Meta } from "/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue?macro=true";
import { default as viewmYVEBfT2oEMeta } from "/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue?macro=true";
import { default as indexZ753QJASLnMeta } from "/usr/src/app/pages/national-targets/my-country/part-1/index.vue?macro=true";
import { default as newoyNds3oRk3Meta } from "/usr/src/app/pages/national-targets/my-country/part-1/new.vue?macro=true";
import { default as edit2QYpjz5YoQMeta } from "/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue?macro=true";
import { default as indexwnpy75vGw7Meta } from "/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue?macro=true";
import { default as viewqURcoCkj38Meta } from "/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue?macro=true";
import { default as indexOJUnEL0XnXMeta } from "/usr/src/app/pages/national-targets/my-country/part-2/index.vue?macro=true";
import { default as newMS6sxO9R2PMeta } from "/usr/src/app/pages/national-targets/my-country/part-2/new.vue?macro=true";
import { default as analyzerENHh3OLECWMeta } from "/usr/src/app/pages/nbsaps/analyzer.vue?macro=true";
import { default as indexyyK4E4AyLJMeta } from "/usr/src/app/pages/nbsaps/index.vue?macro=true";
import { default as edit8Y1LxXRh7vMeta } from "/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue?macro=true";
import { default as indexlp6EFoeuohMeta } from "/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue?macro=true";
import { default as viewNKttmkdBYAMeta } from "/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue?macro=true";
import { default as indexpMPnSS42HFMeta } from "/usr/src/app/pages/nbsaps/my-country/index.vue?macro=true";
import { default as newECpcWBzEP0Meta } from "/usr/src/app/pages/nbsaps/my-country/new.vue?macro=true";
import { default as _91identifier_93DLiRS5ctyCMeta } from "/usr/src/app/pages/search/[identifier].vue?macro=true";
import { default as indexUzt6YGbMnbMeta } from "/usr/src/app/pages/search/index.vue?macro=true";
import { default as _91identifier_93reiPSm5fFaMeta } from "/usr/src/app/pages/taxonomy/gbf/[identifier].vue?macro=true";
import { default as _91identifier_93jWZnRp8Hh0Meta } from "/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue?macro=true";
import { default as indexrSB8j5cgCBMeta } from "/usr/src/app/pages/taxonomy/gbf/index.vue?macro=true";
import { default as email_45alertsANwd1IQgThMeta } from "/usr/src/app/pages/users/email-alerts.vue?macro=true";
import { default as settingsI2eB0HcpFMeta } from "/usr/src/app/pages/users/setting.vue?macro=true";
import { default as _91_91identifier_93_93g2w7yGOZ1pMeta } from "/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue?macro=true";
import { default as requestseemQCdsGWcMeta } from "/usr/src/app/pages/workflows/requests.vue?macro=true";
export default [
  {
    name: "dashboard___ar",
    path: "/ar/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___en___default",
    path: "/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___en",
    path: "/en/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___fr",
    path: "/fr/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___es",
    path: "/es/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___ru",
    path: "/ru/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "dashboard___zh",
    path: "/zh/dashboard",
    meta: dashboardhKii8fXWAxMeta || {},
    component: () => import("/usr/src/app/pages/dashboard.vue")
  },
  {
    name: "database-identifier___ar",
    path: "/ar/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "database-identifier___en___default",
    path: "/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "database-identifier___en",
    path: "/en/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "database-identifier___fr",
    path: "/fr/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "database-identifier___es",
    path: "/es/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "database-identifier___ru",
    path: "/ru/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "database-identifier___zh",
    path: "/zh/database/:identifier()",
    meta: _91identifier_937XfjtpS143Meta || {},
    component: () => import("/usr/src/app/pages/database/[identifier].vue")
  },
  {
    name: "error-403___ar",
    path: "/ar/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-403___en___default",
    path: "/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-403___en",
    path: "/en/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-403___fr",
    path: "/fr/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-403___es",
    path: "/es/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-403___ru",
    path: "/ru/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-403___zh",
    path: "/zh/error/403",
    component: () => import("/usr/src/app/pages/error/403.vue")
  },
  {
    name: "error-404___ar",
    path: "/ar/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-404___en___default",
    path: "/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-404___en",
    path: "/en/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-404___fr",
    path: "/fr/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-404___es",
    path: "/es/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-404___ru",
    path: "/ru/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-404___zh",
    path: "/zh/error/404",
    component: () => import("/usr/src/app/pages/error/404.vue")
  },
  {
    name: "error-500___ar",
    path: "/ar/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "error-500___en___default",
    path: "/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "error-500___en",
    path: "/en/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "error-500___fr",
    path: "/fr/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "error-500___es",
    path: "/es/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "error-500___ru",
    path: "/ru/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "error-500___zh",
    path: "/zh/error/500",
    component: () => import("/usr/src/app/pages/error/500.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___zh",
    path: "/zh",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "knowledge-base-articles-identifier___ar",
    path: "/ar/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles-identifier___en___default",
    path: "/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles-identifier___en",
    path: "/en/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles-identifier___fr",
    path: "/fr/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles-identifier___es",
    path: "/es/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles-identifier___ru",
    path: "/ru/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles-identifier___zh",
    path: "/zh/knowledge-base/articles/:identifier()",
    meta: _91identifier_93QuSrBUP6SFMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/[identifier].vue")
  },
  {
    name: "knowledge-base-articles___ar",
    path: "/ar/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base-articles___en___default",
    path: "/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base-articles___en",
    path: "/en/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base-articles___fr",
    path: "/fr/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base-articles___es",
    path: "/es/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base-articles___ru",
    path: "/ru/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base-articles___zh",
    path: "/zh/knowledge-base/articles",
    meta: indexPK4J6TyKtnMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/articles/index.vue")
  },
  {
    name: "knowledge-base___ar",
    path: "/ar/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base___en___default",
    path: "/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base___en",
    path: "/en/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base___fr",
    path: "/fr/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base___es",
    path: "/es/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base___ru",
    path: "/ru/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base___zh",
    path: "/zh/knowledge-base",
    meta: index9UaQHjx8Q7Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___ar",
    path: "/ar/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___en___default",
    path: "/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___en",
    path: "/en/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___fr",
    path: "/fr/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___es",
    path: "/es/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___ru",
    path: "/ru/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags-tag___zh",
    path: "/zh/knowledge-base/tags/:tag()",
    meta: indexLTysiFnUI5Meta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/[tag]/index.vue")
  },
  {
    name: "knowledge-base-tags___ar",
    path: "/ar/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "knowledge-base-tags___en___default",
    path: "/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "knowledge-base-tags___en",
    path: "/en/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "knowledge-base-tags___fr",
    path: "/fr/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "knowledge-base-tags___es",
    path: "/es/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "knowledge-base-tags___ru",
    path: "/ru/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "knowledge-base-tags___zh",
    path: "/zh/knowledge-base/tags",
    meta: indexZZhFjk9ZfGMeta || {},
    component: () => import("/usr/src/app/pages/knowledge-base/tags/index.vue")
  },
  {
    name: "national-reports___ar",
    path: "/ar/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports___en___default",
    path: "/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports___en",
    path: "/en/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports___fr",
    path: "/fr/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports___es",
    path: "/es/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports___ru",
    path: "/ru/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports___zh",
    path: "/zh/national-reports",
    meta: indexl38GLAvKVHMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/index.vue")
  },
  {
    name: "national-reports-nr7___ar",
    path: "/ar/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7___en___default",
    path: "/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7___en",
    path: "/en/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7___fr",
    path: "/fr/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7___es",
    path: "/es/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7___ru",
    path: "/ru/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7___zh",
    path: "/zh/national-reports/nr7",
    meta: indexsV0YGwxtHhMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___ar",
    path: "/ar/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___en___default",
    path: "/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___en",
    path: "/en/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___fr",
    path: "/fr/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___es",
    path: "/es/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___ru",
    path: "/ru/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit___zh",
    path: "/zh/national-reports/nr7/my-country/edit",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___ar",
    path: "/ar/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___en___default",
    path: "/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___en",
    path: "/en/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___fr",
    path: "/fr/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___es",
    path: "/es/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___ru",
    path: "/ru/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-indicator-data___zh",
    path: "/zh/national-reports/nr7/my-country/edit/indicator-data",
    meta: indicator_45datar4FCObtcGyMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/indicator-data.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___ar",
    path: "/ar/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___en___default",
    path: "/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___en",
    path: "/en/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___fr",
    path: "/fr/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___es",
    path: "/es/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___ru",
    path: "/ru/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-overview___zh",
    path: "/zh/national-reports/nr7/my-country/edit/overview",
    meta: overviewf9kPW4zRjgMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/overview.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___en___default",
    path: "/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___en",
    path: "/en/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___es",
    path: "/es/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-annex___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-annex",
    meta: section_45annexJuwaxMeKOoMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-annex.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___en___default",
    path: "/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___en",
    path: "/en/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___es",
    path: "/es/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-I___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-I",
    meta: section_45IOUxLtY92lYMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-I.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___en___default",
    path: "/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___en",
    path: "/en/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___es",
    path: "/es/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-II___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-II",
    meta: section_45IIvPfvYsvtFnMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-II.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___en___default",
    path: "/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___en",
    path: "/en/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___es",
    path: "/es/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-III___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-III",
    meta: section_45IIIKizDNnkcCkMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-III.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___en___default",
    path: "/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___en",
    path: "/en/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___es",
    path: "/es/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-IV___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-IV",
    meta: section_45IVtc4zFIuIXIMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-IV.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___en___default",
    path: "/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___en",
    path: "/en/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___es",
    path: "/es/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-V___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-V",
    meta: section_45VCVNGerfoo0Meta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-V.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___ar",
    path: "/ar/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___en___default",
    path: "/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___en",
    path: "/en/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___fr",
    path: "/fr/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___es",
    path: "/es/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___ru",
    path: "/ru/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country-edit-section-VI___zh",
    path: "/zh/national-reports/nr7/my-country/edit/section-VI",
    meta: section_45VIgrD5vjTm7tMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/edit/section-VI.vue")
  },
  {
    name: "national-reports-nr7-my-country___ar",
    path: "/ar/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country___en___default",
    path: "/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country___en",
    path: "/en/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country___fr",
    path: "/fr/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country___es",
    path: "/es/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country___ru",
    path: "/ru/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country___zh",
    path: "/zh/national-reports/nr7/my-country",
    meta: indexAMuO34C4sOMeta || {},
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___ar",
    path: "/ar/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___en___default",
    path: "/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___en",
    path: "/en/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___fr",
    path: "/fr/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___es",
    path: "/es/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___ru",
    path: "/ru/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-reports-nr7-my-country-view___zh",
    path: "/zh/national-reports/nr7/my-country/view",
    component: () => import("/usr/src/app/pages/national-reports/nr7/my-country/view/index.vue")
  },
  {
    name: "national-targets-analyzer___ar",
    path: "/ar/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets-analyzer___en___default",
    path: "/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets-analyzer___en",
    path: "/en/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets-analyzer___fr",
    path: "/fr/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets-analyzer___es",
    path: "/es/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets-analyzer___ru",
    path: "/ru/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets-analyzer___zh",
    path: "/zh/national-targets/analyzer",
    meta: analyzer1ndP9iNI4QMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/analyzer.vue")
  },
  {
    name: "national-targets___ar",
    path: "/ar/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets___en___default",
    path: "/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets___en",
    path: "/en/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets___fr",
    path: "/fr/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets___es",
    path: "/es/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets___ru",
    path: "/ru/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets___zh",
    path: "/zh/national-targets",
    meta: indexb9IE1lLRN3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/index.vue")
  },
  {
    name: "national-targets-my-country___ar",
    path: "/ar/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country___en___default",
    path: "/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country___en",
    path: "/en/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country___fr",
    path: "/fr/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country___es",
    path: "/es/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country___ru",
    path: "/ru/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country___zh",
    path: "/zh/national-targets/my-country",
    meta: indexJ6mjSzSz5lMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___ar",
    path: "/ar/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___en___default",
    path: "/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___en",
    path: "/en/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___fr",
    path: "/fr/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___es",
    path: "/es/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___ru",
    path: "/ru/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-edit___zh",
    path: "/zh/national-targets/my-country/part-1/:identifier()/edit",
    meta: editMqCXwCUPWOMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___ar",
    path: "/ar/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___en___default",
    path: "/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___en",
    path: "/en/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___fr",
    path: "/fr/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___es",
    path: "/es/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___ru",
    path: "/ru/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier___zh",
    path: "/zh/national-targets/my-country/part-1/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___ar",
    path: "/ar/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___en___default",
    path: "/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___en",
    path: "/en/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___fr",
    path: "/fr/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___es",
    path: "/es/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___ru",
    path: "/ru/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1-identifier-view___zh",
    path: "/zh/national-targets/my-country/part-1/:identifier()/view",
    meta: viewmYVEBfT2oEMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-1___ar",
    path: "/ar/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1___en___default",
    path: "/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1___en",
    path: "/en/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1___fr",
    path: "/fr/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1___es",
    path: "/es/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1___ru",
    path: "/ru/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1___zh",
    path: "/zh/national-targets/my-country/part-1",
    meta: indexZ753QJASLnMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/index.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___ar",
    path: "/ar/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___en___default",
    path: "/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___en",
    path: "/en/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___fr",
    path: "/fr/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___es",
    path: "/es/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___ru",
    path: "/ru/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-1-new___zh",
    path: "/zh/national-targets/my-country/part-1/new",
    meta: newoyNds3oRk3Meta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-1/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___ar",
    path: "/ar/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___en___default",
    path: "/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___en",
    path: "/en/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___fr",
    path: "/fr/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___es",
    path: "/es/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___ru",
    path: "/ru/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-edit___zh",
    path: "/zh/national-targets/my-country/part-2/:identifier()/edit",
    meta: edit2QYpjz5YoQMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/edit.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___ar",
    path: "/ar/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___en___default",
    path: "/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___en",
    path: "/en/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___fr",
    path: "/fr/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___es",
    path: "/es/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___ru",
    path: "/ru/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier___zh",
    path: "/zh/national-targets/my-country/part-2/:identifier()",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___ar",
    path: "/ar/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___en___default",
    path: "/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___en",
    path: "/en/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___fr",
    path: "/fr/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___es",
    path: "/es/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___ru",
    path: "/ru/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2-identifier-view___zh",
    path: "/zh/national-targets/my-country/part-2/:identifier()/view",
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/[identifier]/view.vue")
  },
  {
    name: "national-targets-my-country-part-2___ar",
    path: "/ar/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2___en___default",
    path: "/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2___en",
    path: "/en/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2___fr",
    path: "/fr/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2___es",
    path: "/es/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2___ru",
    path: "/ru/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2___zh",
    path: "/zh/national-targets/my-country/part-2",
    meta: indexOJUnEL0XnXMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/index.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___ar",
    path: "/ar/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___en___default",
    path: "/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___en",
    path: "/en/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___fr",
    path: "/fr/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___es",
    path: "/es/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___ru",
    path: "/ru/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "national-targets-my-country-part-2-new___zh",
    path: "/zh/national-targets/my-country/part-2/new",
    meta: newMS6sxO9R2PMeta || {},
    component: () => import("/usr/src/app/pages/national-targets/my-country/part-2/new.vue")
  },
  {
    name: "nbsaps-analyzer___ar",
    path: "/ar/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps-analyzer___en___default",
    path: "/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps-analyzer___en",
    path: "/en/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps-analyzer___fr",
    path: "/fr/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps-analyzer___es",
    path: "/es/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps-analyzer___ru",
    path: "/ru/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps-analyzer___zh",
    path: "/zh/nbsaps/analyzer",
    meta: analyzerENHh3OLECWMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/analyzer.vue")
  },
  {
    name: "nbsaps___ar",
    path: "/ar/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps___en___default",
    path: "/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps___en",
    path: "/en/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps___fr",
    path: "/fr/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps___es",
    path: "/es/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps___ru",
    path: "/ru/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps___zh",
    path: "/zh/nbsaps",
    meta: indexyyK4E4AyLJMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___ar",
    path: "/ar/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___en___default",
    path: "/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___en",
    path: "/en/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___fr",
    path: "/fr/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___es",
    path: "/es/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___ru",
    path: "/ru/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier-edit___zh",
    path: "/zh/nbsaps/my-country/:identifier()/edit",
    meta: edit8Y1LxXRh7vMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/edit.vue")
  },
  {
    name: "nbsaps-my-country-identifier___ar",
    path: "/ar/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier___en___default",
    path: "/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier___en",
    path: "/en/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier___fr",
    path: "/fr/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier___es",
    path: "/es/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier___ru",
    path: "/ru/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier___zh",
    path: "/zh/nbsaps/my-country/:identifier()",
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/index.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___ar",
    path: "/ar/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___en___default",
    path: "/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___en",
    path: "/en/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___fr",
    path: "/fr/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___es",
    path: "/es/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___ru",
    path: "/ru/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country-identifier-view___zh",
    path: "/zh/nbsaps/my-country/:identifier()/view",
    meta: viewNKttmkdBYAMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/[identifier]/view.vue")
  },
  {
    name: "nbsaps-my-country___ar",
    path: "/ar/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country___en___default",
    path: "/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country___en",
    path: "/en/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country___fr",
    path: "/fr/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country___es",
    path: "/es/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country___ru",
    path: "/ru/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country___zh",
    path: "/zh/nbsaps/my-country",
    meta: indexpMPnSS42HFMeta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/index.vue")
  },
  {
    name: "nbsaps-my-country-new___ar",
    path: "/ar/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "nbsaps-my-country-new___en___default",
    path: "/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "nbsaps-my-country-new___en",
    path: "/en/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "nbsaps-my-country-new___fr",
    path: "/fr/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "nbsaps-my-country-new___es",
    path: "/es/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "nbsaps-my-country-new___ru",
    path: "/ru/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "nbsaps-my-country-new___zh",
    path: "/zh/nbsaps/my-country/new",
    meta: newECpcWBzEP0Meta || {},
    component: () => import("/usr/src/app/pages/nbsaps/my-country/new.vue")
  },
  {
    name: "search-identifier___ar",
    path: "/ar/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search-identifier___en___default",
    path: "/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search-identifier___en",
    path: "/en/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search-identifier___fr",
    path: "/fr/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search-identifier___es",
    path: "/es/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search-identifier___ru",
    path: "/ru/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search-identifier___zh",
    path: "/zh/search/:identifier()",
    meta: _91identifier_93DLiRS5ctyCMeta || {},
    component: () => import("/usr/src/app/pages/search/[identifier].vue")
  },
  {
    name: "search___ar",
    path: "/ar/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "search___en___default",
    path: "/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "search___ru",
    path: "/ru/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "search___zh",
    path: "/zh/search",
    meta: indexUzt6YGbMnbMeta || {},
    component: () => import("/usr/src/app/pages/search/index.vue")
  },
  {
    name: "taxonomy-gbf-identifier___ar",
    path: "/ar/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-identifier___en___default",
    path: "/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-identifier___en",
    path: "/en/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-identifier___fr",
    path: "/fr/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-identifier___es",
    path: "/es/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-identifier___ru",
    path: "/ru/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-identifier___zh",
    path: "/zh/taxonomy/gbf/:identifier()",
    meta: _91identifier_93reiPSm5fFaMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___ar",
    path: "/ar/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___en___default",
    path: "/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___en",
    path: "/en/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___fr",
    path: "/fr/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___es",
    path: "/es/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___ru",
    path: "/ru/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf-target-identifier___zh",
    path: "/zh/taxonomy/gbf/:target()/:identifier()",
    meta: _91identifier_93jWZnRp8Hh0Meta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/[target]/[identifier].vue")
  },
  {
    name: "taxonomy-gbf___ar",
    path: "/ar/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "taxonomy-gbf___en___default",
    path: "/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "taxonomy-gbf___en",
    path: "/en/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "taxonomy-gbf___fr",
    path: "/fr/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "taxonomy-gbf___es",
    path: "/es/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "taxonomy-gbf___ru",
    path: "/ru/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "taxonomy-gbf___zh",
    path: "/zh/taxonomy/gbf",
    meta: indexrSB8j5cgCBMeta || {},
    component: () => import("/usr/src/app/pages/taxonomy/gbf/index.vue")
  },
  {
    name: "users-email-alerts___ar",
    path: "/ar/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-email-alerts___en___default",
    path: "/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-email-alerts___en",
    path: "/en/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-email-alerts___fr",
    path: "/fr/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-email-alerts___es",
    path: "/es/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-email-alerts___ru",
    path: "/ru/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-email-alerts___zh",
    path: "/zh/users/email-alerts",
    meta: email_45alertsANwd1IQgThMeta || {},
    component: () => import("/usr/src/app/pages/users/email-alerts.vue")
  },
  {
    name: "users-setting___ar",
    path: "/ar/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "users-setting___en___default",
    path: "/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "users-setting___en",
    path: "/en/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "users-setting___fr",
    path: "/fr/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "users-setting___es",
    path: "/es/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "users-setting___ru",
    path: "/ru/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "users-setting___zh",
    path: "/zh/users/setting",
    component: () => import("/usr/src/app/pages/users/setting.vue")
  },
  {
    name: "workflows-batches-batchId-identifier___ar",
    path: "/ar/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-batches-batchId-identifier___en___default",
    path: "/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-batches-batchId-identifier___en",
    path: "/en/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-batches-batchId-identifier___fr",
    path: "/fr/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-batches-batchId-identifier___es",
    path: "/es/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-batches-batchId-identifier___ru",
    path: "/ru/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-batches-batchId-identifier___zh",
    path: "/zh/workflows/batches/:batchId()/:identifier?",
    meta: _91_91identifier_93_93g2w7yGOZ1pMeta || {},
    component: () => import("/usr/src/app/pages/workflows/batches/[batchId]/[[identifier]].vue")
  },
  {
    name: "workflows-requests___ar",
    path: "/ar/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  },
  {
    name: "workflows-requests___en___default",
    path: "/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  },
  {
    name: "workflows-requests___en",
    path: "/en/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  },
  {
    name: "workflows-requests___fr",
    path: "/fr/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  },
  {
    name: "workflows-requests___es",
    path: "/es/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  },
  {
    name: "workflows-requests___ru",
    path: "/ru/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  },
  {
    name: "workflows-requests___zh",
    path: "/zh/workflows/requests",
    component: () => import("/usr/src/app/pages/workflows/requests.vue")
  }
]